<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <el-button type="primary" size="medium" @click="dialogAdd = true,title = '添加角色'">添加新角色</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="role_id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="name" label="角色名字" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="content" label="备注" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" v-if="scope.row.role_id != 1" @click="updateFill(scope.row)">权限设置</span>
          <span class="operation" v-if="scope.row.role_id != 1" @click="editor(scope.row)">编辑</span>
          <span class="operation" v-if="scope.row.role_id != 1" @click="del(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />

    <!-- 添加修改 -->
    <el-dialog :title="title" v-model="dialogAdd" width="492px" top="35vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="form_box">
        <div class="form_title">角色名称：</div>
        <el-input v-model="addArr.name" placeholder="角色名称" size="small"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">角色备注：</div>
        <el-input v-model="addArr.content" placeholder="角色备注" size="small"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="add" v-if="title == '添加角色'">确认添加</el-button>
          <el-button type="primary" @click="edit" v-else>确认修改</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 修改权限 -->
    <el-dialog title="修改权限" v-model="permissions" width="492px" top="20vh" :close-on-click-modal="false" @close="closeDialog2">
      <div class="form_box">
        <el-tree ref="tree" :data="data" show-checkbox node-key="id" :default-expand-all="true" :default-checked-keys="checked" :props="defaultProps"></el-tree>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="update">确认更新</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  getList,
  add,
  update,
  edit,
  del,
  getPermissions,
} from "@/api/admin/role";
import page from "@/components/page";
export default {
  name: "adminRole",
  components: {
    page,
  },
  data() {
    return {
      pager: { total: 0, page: 1, rows: 10 },
      tableData: [], //数据
      row: [], //选中的数据
      title: "", //弹窗标题
      dialogAdd: false, //添加弹窗
      addArr: { name: "", content: "" }, //添加数组
      permissions: false, //权限弹窗
      data: [], //权限数组
      checked: [], //默认选中数组
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
    this.getPermissions();
  },
  methods: {
    //获取管理员数据
    getList() {
      getList([
        { key: "page", val: this.pager.page },
        { key: "row", val: this.pager.rows },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.pager.total = res.data.total;
            this.tableData = res.data.list;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取全部权限
    getPermissions() {
      getPermissions().then((res) => {
        if (res.code == 0) {
          this.data = res.data;
        } else {
          this.$message({ message: res.msg, type: "error" });
        }
      });
    },
    //添加角色
    add() {
      add([
        { key: "name", val: this.addArr.name },
        { key: "content", val: this.addArr.content },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.dialogAdd = false;
            this.getList();
            this.$message({ message: res.msg, type: "success" });
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //编辑前数据填充
    editor(row) {
      this.row = row;
      this.title = `编辑角色(ID：${row.role_id})`;
      this.addArr = { name: row.name, content: row.content };
      this.dialogAdd = true;
    },
    //编辑
    edit() {
      if(this.row.name == this.addArr.name){
        this.addArr.name = 'a';
      }
      edit([
        { key: "role_id", val: this.row.role_id },
        { key: "name", val: this.addArr.name },
        { key: "content", val: this.addArr.content },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.dialogAdd = false;
            this.getList();
            this.$message({ message: res.msg, type: "success" });
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //关闭正常弹窗后清空
    closeDialog() {
      this.addArr = { name: "", content: "" }; //清空添加修改弹窗数据
      this.row = []; //清空选中数据
    },
    //关闭修改权限弹出后清空
    closeDialog2() {
      this.$refs.tree.setCheckedKeys([]); //清空权限数据
    },
    //权限修改前数据填充
    updateFill(row) {
      this.row = row;
      let arr = row.access_list.split(",");
      this.checked = arr;
      this.permissions = true;
    },
    //权限修改
    update() {
      update([
        { key: "role_id", val: this.row.role_id },
        { key: "checked", val: this.$refs.tree.getHalfCheckedKeys().concat(this.$refs.tree.getCheckedKeys()).join(",") },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.msg, type: "success" });
            this.permissions = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //删除角色
    del(row) {
      this.row = row;
      this.$confirm(
        "你确定要删除角色：" + row.name + "吗？此操作无法撤回,请谨慎操作",
        "提示",
        { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      )
        .then(() => {
          del([{ key: "role_id", val: this.row.role_id }])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.$message({ message: res.msg, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
</style>
